<!--  -->
<template>
<div class=''>
    <router-view></router-view>
</div>
</template>

<script>


export default {

}
</script>
<style>
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
</style>